import { HelmetProvider } from "react-helmet-async";
import "./App.css";
import UserRouter from "./router";

function App() {

  return (
    <HelmetProvider>
      <UserRouter />
    </HelmetProvider>
  );
}

export default App;
