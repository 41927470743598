import TopBar from "../../components/LandingPage/Topbar";
import { useTheme, Accordion, useToast } from "@chakra-ui/react";
import CustomAccordionItem from "./../../components/LandingPage/CustomAccordian";
import Instagram from '../../asset/landingImages/instagram.png'
import Twitter from '../../asset/landingImages/twitter.png'
import TikTok from '../../asset/landingImages/tiktok.png'
import SeeYouStore from '../../asset/landingImages/seeyou_stores.jpeg'
import DownloadAndroid from '../../asset/landingImages/download_android.png'
import DownloadApple from '../../asset/landingImages/download_apple.png'
import Landing3 from '../../asset/landingImages/landing3.png'

import collage from '../../asset/landingImages/collage_animated.png'

import {
  appstoreimg,
  playstoreimg,
  benefit2,
  benefit3,
  benefit4,
  circleframe,
  lineframe,
  cardgrid,
  cardgrid1,
  cardgrid2,
  cardgrid3,
  cardgrid4,
  cardgrid5,
  acccordianicon,
  acccordianicon1,
  acccordianicon2,
  acccordianicon3,
  arrowRight,
  seeyouLogo,
  features,
  sync_icon,
  calendar_icon,
  location_icon,
  alarm_icon,
  benefit1
} from "./../../assets";
import { useEffect, useRef, useState } from "react";
import SEO from "../../components/SEO";
import axios from "axios";

export default function LandingPage() {
  const theme = useTheme();
  const toast = useToast();


  const cardRows = [
    [
      { img: cardgrid },
      { img: cardgrid1 }
    ],
    [
      { img: cardgrid2 },
      { img: cardgrid3 }
    ],
    [
      { img: cardgrid4 },
      { img: cardgrid5 }
    ]
  ];



  const messages = ['Tomorrow.', 'Later.', 'Soon.'];
  const [currentMessage, setCurrentMessage] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showCursor, setShowCursor] = useState(true);

  // Cursor blink effect
  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor(prev => !prev);
    }, 500);

    return () => clearInterval(cursorInterval);
  }, []);

  // Typing animation effect
  useEffect(() => {
    const handleTyping = () => {
      if (currentMessage.length < messages[currentIndex].length) {
        setCurrentMessage(prev => messages[currentIndex].slice(0, prev.length + 1));
      } else {
        setIsTyping(false);
        setTimeout(() => setIsDeleting(true), 500);
      }
    };

    const handleDeleting = () => {
      if (currentMessage.length > 0) {
        setCurrentMessage(prev => prev.slice(0, -1));
      } else {
        setIsDeleting(false);
        if (currentIndex < messages.length - 1) {
          setCurrentIndex(prev => prev + 1);
          setIsTyping(true);
        } else {
          // Reset to first message
          setCurrentIndex(0);
          setIsTyping(true);
        }
      }
    };

    const timer = setTimeout(() => {
      if (isTyping) {
        handleTyping();
      } else if (isDeleting) {
        handleDeleting();
      }
    }, isTyping ? 150 : 75);

    return () => clearTimeout(timer);
  }, [currentMessage, currentIndex, isTyping, isDeleting]);

  const faqData = [
    {
      id: 1,
      title: "How do I create an event?",
      content: "Tap the \"Create Event\" button, add the event details such as time, location, and description, then select the contacts you want to invite. Once saved, your event will appear on your calendar.",
      icon: acccordianicon
    },
    {
      id: 2,
      title: "Can I share my location with others?",
      content: "Yes, you can share your location with event attendees by enabling location sharing within the event settings. Your friends will see your location when the event starts, helping everyone coordinate better.",
      icon: acccordianicon1
    },
    {
      id: 3,
      title: "Will I get reminders for my events?",
      content: "Absolutely! You’ll receive reminders for upcoming events. You can customize the reminder times to get notified minutes, hours, or even days before the event begins.",
      icon: acccordianicon2
    },
    {
      id: 4,
      title: "Can I sync See You with my other calendars?",
      content: "Yes, See You supports calendar syncing. Go to settings and select the option to sync with other calendars, such as Google Calendar or iCal, so you can view all your events in one place.",
      icon: acccordianicon3
    }
  ];

  const features_list = [
    {
      icon: calendar_icon,
      title: "Easy scheduling",
      description: "Create events in just a few taps and share them with friends instantly, so everyone stays in the loop.",
      bgColor: "bg-emerald-50",
    },
    {
      icon: alarm_icon,
      title: "Smart Reminders",
      description: "Never miss an event or task with customizable reminders that keep you updated.",
      bgColor: "bg-purple-50",
    },
    {
      icon: location_icon,
      title: "Smart locations",
      description: "Make event and meeting coordination perfect. Have all participants arrive within minutes of each other with optimized location trackers.",
      bgColor: "bg-pink-50",
    },
    {
      icon: sync_icon,
      title: "Sync Across Devices",
      description: "Access your schedules from any device, ensuring you're always connected, no matter where you are.",
      bgColor: "bg-blue-50",
    }
  ];

  // Add ref for mailing list section
  const mailingListRef = useRef(null);

  // Add scroll function
  const scrollToMailingList = () => {
    mailingListRef.current?.scrollIntoView({ behavior: 'smooth' });
  };


  // Add states for email input and loading
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  // Email validation regex
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // Handle form submission
  const handleSubscribe = async () => {
    // Validate empty email
    if (!email) {
      toast({
        title: "Email Required",
        description: "Please enter your email address",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });
      return;
    }

    // Validate email format
    if (!emailRegex.test(email)) {
      toast({
        title: "Invalid Email",
        description: "Please enter a valid email address",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios({
        method: 'POST',
        url: 'https://api.seeyoucalendar.com/api/v1/mailing/mailing-list',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          email,
          tags: 'landing_page'
        }
      });

      if (response.status) {
        toast({
          title: "Success!",
          description: "Thank you for subscribing! We'll keep you updated.",
          type: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right"
        });
        setEmail(''); // Clear input
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      toast({
        title: "Subscription Failed",
        description: error.response?.data?.message || 'Failed to subscribe. Please try again.',
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right"
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <SEO
        title="See You - Smart Calendar & Event Planning"
        description="Schedule smarter with See You. Plan events, get reminders, and coordinate seamlessly with your team and friends."
      />
      <div className="xl:w-full xl:h-full">
        <TopBar onGetStarted={scrollToMailingList} />
        {/* Hero Section with Text */}
        <div className="w-full flex flex-col">
          {/* Image Section - First on mobile */}
          <div className="w-full order-1 sm:order-3 mb-8 sm:mb-0 sm:mt-8">
            <div className="w-full">
              <img
                src={Landing3}
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
          </div>

          {/* Text Content - Second on mobile */}
          <div className="w-full flex justify-center order-2">
            <div className="flex flex-col items-center px-6 md:px-8 xl:px-20">
              <h1 className="text-[40px] sm:text-[60px] md:text-[75px] lg:text-[90px] font-semibold leading-[46px] sm:leading-[66px] md:leading-[81px] lg:leading-[96px] text-center">
                Meet your
              </h1>
              <h1 className="text-[40px] sm:text-[60px] md:text-[75px] lg:text-[90px] font-semibold leading-[46px] sm:leading-[66px] md:leading-[81px] lg:leading-[96px] text-center">
                <span style={{ color: theme.colors.primary01[100] }}>
                  scheduling
                </span>{" "}
                Buddy
              </h1>

              <p className="calender_desc mt-3 xl:mt-5 text-center max-w-[800px]">
                Your calendar reimagined to be simpler & smarter. Schedule, plan and
                track with all participants through various tasks and events
                seamlessly.
              </p>
            </div>
          </div>

          {/* Store Buttons - Third on mobile */}
          {/* <div className="w-full flex justify-center mt-6 xl:mt-8 order-3 sm:order-2">
            <div className="flex flex-col sm:flex-row items-center gap-4 xl:gap-8 px-6">
              <div className="play_store_cont">
                <img src={playstoreimg} alt="" className="play_store_cont_img1" />
              </div>
              <div className="play_store_cont">
                <img src={appstoreimg} alt="" className="play_store_cont_img1" />
              </div>
            </div>
          </div> */}

        </div>
        {/* Benefits Button Section */}
        {/* <div className="flex justify-center mt-20 sm:mt-40 xl:mt-60 px-6 sm:px-8 md:px-12">
          <button
            style={{
              backgroundColor: theme.colors.primary01[300],
              color: theme.colors.primary01[400],
            }}
            className="px-6 py-2 rounded-full text-sm font-medium"
          >
            Benefits
          </button>
        </div> */}

        {/* Love See You Section */}
        <div className="relative px-6 sm:px-8 md:px-12 mt-8 sm:mt-10">
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-[76px] text-center">
            You're Going to Love{" "}
            <span
              style={{ backgroundImage: theme.colors.gradiant_primary[200] }}
              className="bg-clip-text text-transparent inline-block"
            >
              See You
            </span>{" "}
          </h1>

          {/* Decorative Elements */}
          {/* <div className="absolute top-4 left-4 sm:left-8 md:left-14 hidden sm:block">
            <img
              src={PolyganBanner}
              alt="polygon"
              className="w-[20px] sm:w-[10px] md:w-[20px] h-auto"
            />
          </div>

          <div className="absolute bottom-4 right-4 sm:right-8 md:right-14 hidden sm:block">
            <img
              src={PolyganBanner2}
              alt="polygon"
              className="w-[10px] sm:w-[50px] md:w-[20px] h-auto"
            />
          </div> */}
        </div>

        {/* Benefits Grid */}
        {/* <div className="grid grid-cols-2 gap-6 max-w-[1200px] mx-auto p-8">
        {features_list.map((feature, index) => (
          <div key={index} className={`${index === 0 ? 'bg-[#F4FBF7]' : index === 1 ? 'bg-[#F9F5FF]' : index === 2 ? 'bg-[#FFF5F5]' : 'bg-[#F5F8FF]'} rounded-3xl p-12`}>
            <div className="bg-white rounded-full w-14 h-14 flex items-center justify-center mb-8 shadow-sm">
              <img src={feature.icon} alt={feature.title} className="w-6 h-6" />
            </div>
            <h2 className="text-xl font-semibold mb-4">{feature.title}</h2>
            <p className="text-gray-600 leading-relaxed">{feature.description}</p>
          </div>
        ))}
      </div> */}
        <div className="px-6 sm:px-8 md:px-12 mt-5 sm:mt-8 xl:mt-5">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 xl:gap-8 max-w-6xl mx-auto">
            <div className="w-full">
              <img
                src={benefit1}
                alt="Easy Scheduling"
                className="w-full h-auto object-contain"
              />
            </div>
            <div className="w-full">
              <img
                src={benefit2}
                alt="Smart Reminders"
                className="w-full h-auto object-contain"
              />
            </div>
            <div className="w-full">
              <img
                src={benefit3}
                alt="Smart Locations"
                className="w-full h-auto object-contain"
              />
            </div>
            <div className="w-full">
              <img
                src={benefit4}
                alt="Sync across device"
                className="w-full h-auto object-contain"
              />
            </div>
          </div>
        </div>

        {/* Hero Section */}
        <div className="relative w-full h-[30vh] sm:h-[100vh] overflow-hidden my-10">
          {/* Background Image */}
          <div
            className="absolute inset-0 bg-center bg-no-repeat bg-cover"
            style={{
              backgroundImage: collage ? `url(${collage})` : 'none',
            }}
          />

          {/* Animated Text */}
          <div className="absolute inset-0 flex items-center justify-center px-4">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl text-white text-center">
              See You {currentMessage}
              <span
                className={`inline-block w-[2px] h-8 sm:h-10 lg:h-12 bg-white ml-1 align-middle ${showCursor ? 'opacity-100' : 'opacity-0'
                  }`}
              />
            </h1>
          </div>
        </div>


        <div className="relative h-auto sm:h-[400px] md:h-[474px] flex flex-col justify-center items-center text-center mt-8 md:mt-10 xl:mt-10 px-4 md:px-6 xl:px-8">
          <div className="max-w-[697px]">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-[64px] font-medium leading-tight sm:leading-[64px] mt-5">
              Smart Features to Keep You{" "}
              <span
                className="bg-clip-text text-transparent inline-block"
                style={{ backgroundImage: theme.colors.gradiant_primary[200] }}
              >on Track</span>
            </h1>

            <p className="text-lg sm:text-xl md:text-2xl text-[#19191c] font-normal leading-[1.2] sm:leading-[29.23px] mt-2 md:mt-3 xl:mt-3 max-w-[697px] mx-auto">
              See You comes packed with tools to make your scheduling, planning, and coordinating easier than ever
            </p>
          </div>
          <img
            src={lineframe}
            alt=""
            className="hidden md:block absolute top-0 w-[60px] md:w-[80px] lg:w-auto"
          />
        </div>
        {/* Cards Container */}
        <div className="max-w-[1440px] mx-auto px-4  md:mt:10 sm:-mt-10 sm:px-6 md:px-8">

          <img
            src={features}
            // alt={`Card ${rowIndex}-${cardIndex}`}
            className="w-full h-auto rounded-lg mt-5"
          />

          {/* 
          {cardRows.map((row, rowIndex) => (
            <div
              key={rowIndex}
              className="mb-5 last:mb-0"
            >
              <div className="flex flex-col sm:flex-row sm:gap-5">
                {row.map((card, cardIndex) => (
                  <div
                    key={cardIndex}
                    className={`
                  w-full mb-5 sm:mb-0
                  ${cardIndex === 0 ? 'sm:w-[65%]' : 'sm:w-[35%]'}
                  ${rowIndex === 1 ? (cardIndex === 0 ? 'sm:w-[35%]' : 'sm:w-[75%]') : ''}
                `}
                  >
                    <img
                      src={card.img}
                      alt={`Card ${rowIndex}-${cardIndex}`}
                      className="w-full h-auto rounded-lg"
                    />
                  </div>
                ))}
              </div>
            </div>
          ))} */}


        </div>
        {/* FAQ */}
        <div className="w-full px-4 sm:px-6 lg:px-20 py-10">
          {/* Grid Container */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12 max-w-7xl mx-auto items-center">
            {/* FAQ Title Section - First on mobile */}
            <div className="order-1 lg:order-2 relative flex flex-col items-center lg:items-start">
              <div className="text-center lg:text-left px-4 lg:px-20">
                <div className="mt-0 lg:mt-16">
                  <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold">FAQs</h1>
                  <p className="mt-4 text-lg sm:text-xl text-gray-700">
                    Here are the most common questions <br className="hidden lg:block" /> about using See You.
                  </p>
                </div>
              </div>
            </div>

            {/* Accordion Section - Second on mobile */}
            <div className="order-2 lg:order-1 space-y-4 sm:space-y-6 lg:space-y-[20px] flex justify-center lg:justify-start">
              <div className="w-full max-w-md">
                {faqData.map((faq) => (
                  <Accordion key={faq.id} allowToggle>
                    <CustomAccordionItem
                      title={faq.title}
                      icon={faq.icon}
                    >
                      {faq.content}
                    </CustomAccordionItem>
                  </Accordion>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* FAQ */}


        {/* mailing list */}
        <div
          ref={mailingListRef}
          className="bgFeature py-20 mt-8 md:mt-[20px] xl:mt-[40px] flex justify-center items-center flex-col relative px-4 md:px-6 border"
        >
          <h1 className="text-4xl md:text-5xl xl:text-[64px] font-semibold text-center mt-8 md:mt-0 leading-snug">
            Join Our <span className="mailing_list">Mailing List</span>
          </h1>

          <p className="text-lg md:text-xl xl:text-2xl font-normal text-center px-4 mt-4">
            Subscribe to get updates, insights, and be the first to know{" "}
            <br className="hidden md:block" /> when we launch!
          </p>

          <div className="w-full md:w-[350px] xl:w-[400px] h-[64px] mt-[30px] md:mt-[40px] xl:mt-[50px] mailing_inpt_cont px-4 md:px-0">
            <div className="mailing_inpt_inner w-full h-full flex items-center">
              <input
                type="email"
                className="w-[70%] h-full bg-transparent border-none outline-none placeholder-gray-500 text-gray-800"
                name="email"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSubscribe();
                  }
                }}
              />
              <div className="relative">
                <button
                  className={`flex items-center mailing_btn hover:opacity-75 transition-opacity ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={handleSubscribe}
                  disabled={isLoading}
                >
                  <p>{isLoading ? 'Sending...' : 'Send'}</p>
                  {!isLoading && <img src={arrowRight} alt="submit" className="ml-2" />}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* mailing list */}

        {/* Hero Section */}
        <div className="relative min-h-screen w-full overflow-hidden">
          {/* Background Image Container */}
          <div
            className="absolute inset-0 w-full h-full bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${SeeYouStore})`,
              minHeight: '100vh'
            }}
          >
            {/* Overlay */}
            <div className="absolute inset-0 bg-black/50"></div>
          </div>

          {/* Content Container */}
          <div className="relative min-h-screen w-full flex flex-col items-center justify-center text-center px-4 py-20">
            {/* Heading */}
            <h1 className="text-white text-5xl md:text-7xl mb-12 tracking-wide drop-shadow-lg italic">
              See <span className="text-white italic">You</span> Soon!
            </h1>


            {/* Store Buttons Container */}
            {/* <div className="flex flex-col sm:flex-row gap-6 mt-2">
              <a
                href="#"
                className="transform hover:scale-105 transition-transform duration-300"
              >
                <img
                  src={DownloadAndroid}
                  alt="Get it on Google Play"
                // className="h-22 w-auto"
                />
              </a>
              <a
                href="#"
                className="transform hover:scale-105 transition-transform duration-300"
              >
                <img
                  src={DownloadApple}
                  alt="Download on the App Store"
                // className="h-25 w-auto"
                />
              </a>
            </div> */}
          </div>
        </div>
        {/* Hero Section */}
        <footer className="w-full px-8 py-12 bg-white border-t">
          <div className="max-w-6xl mx-auto">
            {/* Logo and Tagline */}
            <div className="mb-8">
              <img src={seeyouLogo} alt="" />
              <p className="text-gray-600">Simpler, Smilier, Smarter</p>
            </div>

            <div className="flex flex-wrap justify-between mb-8">
              {/* Social Media Icons */}
              <div className="flex gap-4 mb-8 md:mb-0">
                <a
                  href="#"
                  className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-all duration-300 flex items-center justify-center w-10 h-10"
                >
                  <img src={TikTok} alt="Tiktok Logo" className="w-5 h-5" />
                </a>
                <a
                  href="#"
                  className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-all duration-300 flex items-center justify-center w-10 h-10"
                >
                  <img src={Instagram} alt="Instagram Logo" className="w-5 h-5" />
                </a>
                <a
                  href="#"
                  className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-all duration-300 flex items-center justify-center w-10 h-10"
                >
                  <img src={Twitter} alt="Twitter Logo" className="w-5 h-5" />
                </a>
              </div>

              {/* Navigation Links */}
              <div className="flex flex-wrap gap-12">
                {/* Support Section */}
                <div>
                  <h3 className="font-semibold text-gray-800 mb-4">SUPPORT</h3>
                  <ul className="space-y-2">
                    <li>
                      <a href="#" className="text-gray-600 hover:text-gray-800 transition-colors">
                        Help
                      </a>
                    </li>
                    <li>
                      <a href="#" className="text-gray-600 hover:text-gray-800 transition-colors">
                        Feedback
                      </a>
                    </li>
                  </ul>
                </div>

                {/* Legal Section */}
                <div>
                  <h3 className="font-semibold text-gray-800 mb-4">LEGAL</h3>
                  <ul className="space-y-2">
                    <li>
                      <a href="#" className="text-gray-600 hover:text-gray-800 transition-colors">
                        Privacy policy
                      </a>
                    </li>
                    <li>
                      <a href="#" className="text-gray-600 hover:text-gray-800 transition-colors">
                        Terms & Conditions
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Copyright */}
            <div className="text-center text-gray-600 text-sm">
              © {new Date().getFullYear()} See You. All rights reserved.
            </div>
          </div>
        </footer>
      </div >
    </>
  );
}
