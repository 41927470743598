// components/SEO/index.js
import { Helmet } from 'react-helmet-async';
import { seeyouLogo } from '../assets';
const DEFAULT_SEO = {
  title: "See You - Your Smart Calendar App",
  description: "Your calendar reimagined to be simpler & smarter. Schedule, plan and track with all participants through various tasks and events seamlessly.",
  keywords: "calendar, scheduling, events, planning, reminders, smart calendar",
};

const SEO = ({ 
  title = DEFAULT_SEO.title,
  description = DEFAULT_SEO.description,
  keywords = DEFAULT_SEO.keywords,
}) => {
  return (
    <Helmet>
      {/* Basic Metadata */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={seeyouLogo} />
      <meta property="og:image:alt" content="See You Logo" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={seeyouLogo} />
      <meta name="twitter:image:alt" content="See You Logo" />

      {/* Additional metadata */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, follow" />
      <meta name="language" content="English" />
    </Helmet>
  );
};

export default SEO;