import { seeyouLogo } from "./../../assets";
import { useTheme } from "@chakra-ui/react";

export default function TopBar({ onGetStarted }) {
  const theme = useTheme();

  return (
    <div className="w-full flex justify-between items-center px-6 sm:px-8 md:px-12 lg:px-16 xl:px-20 py-6 sm:py-8 md:py-12 xl:py-10">
      <div>
        <img src={seeyouLogo} alt="See You Logo" className="w-auto h-8 md:h-auto" />
      </div>

      <div>
        <button
          onClick={onGetStarted}
          style={{ backgroundImage: theme.colors.gradiant_primary[100], borderRadius:13 }}
          className="px-4 sm:px-6 py-2 sm:py-3 text-sm sm:text-base font-medium text-white hover:opacity-90 transition-opacity"
        >
          Get Started!
        </button>
      </div>
    </div>
  );
}