import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";

const CustomAccordionItem = ({ title, children, icon }) => (
  <AccordionItem border="none">
    {({ isOpen }) => (
      <div
        className={`relative flex items-start gap-3 sm:gap-5 p-4 rounded-lg transition-all duration-300 ${
          isOpen ? "bg-gray-50" : ""
        }`}
      >
        {/* Icon Container */}
        <div className="relative flex-shrink-0 w-8 sm:w-10 md:w-12">
          <img 
            src={icon} 
            alt={`${title} icon`}
            className="w-full h-auto object-contain"
          />
        </div>

        {/* Content Container */}
        <div className="flex-grow">
          <h2>
            <AccordionButton 
              px={0} 
              py={2}
              _hover={{ bg: 'transparent' }}
              className="w-full"
            >
              <Box
                as="span"
                flex="1"
                textAlign="left"
                className={`text-lg sm:text-xl md:text-2xl font-medium ${
                  isOpen ? "text-primary-600" : ""
                }`}
              >
                {title}
              </Box>
              <AccordionIcon 
                className={`transform transition-transform duration-300 flex-shrink-0 ${
                  isOpen ? "rotate-180" : ""
                }`}
              />
            </AccordionButton>
          </h2>
          <AccordionPanel 
            pb={4} 
            px={0}
            className="text-base sm:text-lg text-gray-600"
          >
            {children}
          </AccordionPanel>
        </div>
      </div>
    )}
  </AccordionItem>
);

export default CustomAccordionItem;